// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authRedirectUri: '/',
  authPostLogoutRedirectUri: '/',
  datexApplicationApiUrl: '/api/',
  integrationHubConfigApiUrl: '',
  integrationHubConfigurationApiScopes: [],
  legacyConnectorsConfigApiUrl: '',
  legacyConnectorsConfigApiScopes: [],
  integrationHubApiUrl: '',
  integrationHubApiScopes: [],
  integrationHubLoggingApiUrl: '',
  integrationHubLoggingApiScopes: [],
  datexAlertApiUrl: '',
  datexAlertApiScopes: [],
  datexAlertRecommndedSolutionApiUrl: '',
  datexAlertRecommndedSolutionApiScopes: [],
  kustoQueryApiUrl: 'https://ca-integrationhub-kustoquery-api.victoriousmeadow-f970156f.eastus.azurecontainerapps.io/',
  kustoQueryApiScopes: ['api://709bda71-a43e-4070-b6c7-dcc72df15a01/.default'],
  footPrintAdaptorApiUrl: '',
  footPrintAdaptorApiScopes: [],
  shopifyAdaptorApiUrl: '',
  shopifyAdaptorApiScopes: [],
  wooCommerceAdaptorApiUrl: '',
  wooCommerceAdaptorApiScopes: [],
  bigCommerceAdaptorApiUrl: '',
  bigCommerceAdaptorApiScopes: [],
  grapcityLicenseKey: '*.wavelength.host,654486356437283#B1n2C8UTTzN6ckFWRvdGdHtkYT3CdxczSwE5M9djZTVEVTNEORRVN8pmd9JDUnJHOaZnaXl7QkRkNDFmUM3UNZVkcqNkWIN6doNkWtBVVNN5ZPlTR5l6QylFVS9mMrUEWQVUaGREaRd6MQpEZwEHRHtCVxEWa0BjQRFTW92UaThXMKdVSPZGdzMXVyFVV7QTZCBDSzJDd6R5Ur2CR8ZUO8YFTF34YhBTbhtWeL5mZXhGdp56YR9EelVna0pHT6c6aChmW8Y5N4cXSxE7RX3EeUpWQwRlT6BzcCdWdMtCczd5SmtiS8cDR7U6MjdEV4ZkI0IyUiwiI8MjQ5Q4Q9MjI0ICSiwSOwMTM4MTM4EjM0IicfJye&Qf35VfiQlRWllI0IyQiwiI4YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsIiMxgDN4ADIyEDNwMjMwIjI0ICdyNkIsICdz3GaugGdn9WZsVmdhdnLqIiOiMXbEJCLigXZ4FGRiojIh94QiwiIzgjM7MDN6UzM6gDN4UjNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQTlUUSZXOMZ6NolTZu9WdNRXMFtWNkd4UWJHWad4SvMmVYhGRapXUWdWOQRjYmNDR8xmQiVnN5Z7LLdVZ9JkN9sCTxIzbyokZjFUWjJXQ9FVQ0R4NCB5NaZ6KatSUatybWNGVxrk33',
  ediLogicAppTriggerUrl: 'https://prod-39.eastus.logic.azure.com/workflows/3c4de442557e47e6a1cea6ff8b17201a/triggers/manual/paths/invoke/%7BAction%7D?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=SeL8gpRdxvgdTvKeBzVZlEA4FVuU_UgviUEawmCR02c',
  wavelengthAccountConnectorUrl: 'https://wavelength-connector-edi-test.eastus.cloudapp.azure.com/',
  authtok: 'gJxjmNebq3g0M9XIEcXHa2PiODl8roVO',
  copilotApiUrl: 'https://copilot.wavelength.host/api/codecopilot/analyze/',
  copilotApiScopes: ['api://b24fc21e-e3f5-48c1-9769-77ed844e7b66/.default'],
  icmApiUrl: 'https://icm.wavelength.host/odata/',
  icmApiScopes: ['api://ad5d6ff0-895d-4d96-aa09-62ddc99c68de/.default'],
  ediPublic: 'https://test-as2.wavelength.host/AS2Ingress',
  ediPrivate: 'https://wavelength-edi-test.eastus.cloudapp.azure.com/AS2Ingress',
  datexDefaultAlertMessageTemplate: 'https://datexintegrationhub.blob.core.windows.net/datex-alert-services/html-email-templates/IntegrationHubBigEcommerceOrderUpdate.html'
};
