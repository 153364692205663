import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Core } from '@grapecity/activereports';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'codemirror/lib/codemirror';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
// for future reference addons can be added for more functions for example lint, 
// although some of them require extra packages to be installed or else you will get an error
// example: import 'codemirror/addon/lint/lint';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

Core.setLicenseKey(environment.grapcityLicenseKey);
